const TokenKey = 'AuthTokenManagerCMS'

export function getToken () {
  // console.log('getItem ' + TokenKey)
  return localStorage.getItem(TokenKey)
}

export function setToken (token) {
  // console.log('setItem ' + token)
  return localStorage.setItem(TokenKey, token)
}

export function removeToken () {
  // console.log('removeItem ' + TokenKey)
  return localStorage.removeItem(TokenKey)
}
