<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.label || $attrs.labelValidation"
    :rules="rules"
    v-slot="{ errors }"
  >
    <el-form-item :error="errors[0]" :label="$attrs.label">

      <el-input-number
      v-on="inputListeners"
      v-bind="$attrs"
      v-model="innerValue"
      :ref="inputRef"

      />
    </el-form-item>
  </ValidationProvider>
</template>

<script>

import inputElemet from './mixin/inputElemet'

export default {
  mixins: [inputElemet],
  created () {

    // console.log('created -> this.configElement', this.configElement)
    // console.log('created -> this.innerValue', this.innerValue)
  }
}
</script>
