
<template>
  <div
    class="loader d-flex w-100 h-100"
    @change="change"
    @dragover="dragover"
    @drop="drop"
  >
    <label
      class="w-100 h-100 d-flex justify-content-center align-items-center flex-column"
    >
      <i class="el-icon-upload"></i> Drop image here or

      <span class="browse"> browse...</span>
      <div v-if="frontRules.length">
        <h3>Requisites:</h3>
        <span class="requisites" v-for="(rule, index) in frontRules" :key="index">
          {{ rule.label }}
        </span>
      </div>
      <ValidationProvider
        name="image file"
        ref="fileProvider"
        :rules="rules"
        v-slot="{ errors }"
      >
        <input id="file" class="sr-only" type="file" accept="image/*" />
        <p>{{ errors[0] }}</p>
      </ValidationProvider>
    </label>
  </div>
</template>

<script>
const URL = window.URL || window.webkitURL

export default {
  name: 'Loader',

  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    frontRules: {
      type: [Array, String],
      default: ''
    }
  },
  computed: {
    rules: function () {
      const isArray = Array.isArray(this.frontRules)
      if (!isArray) {
        return this.frontRules
      }

      const values = []
      for (const item of this.frontRules) {
        values.push(item.value)
      }
      return values.join('|')
    }
  },
  methods: {
    async read (files) {
      const file = files[0]
      const fileInput = await this.$refs.fileProvider.validate(file)
      if (!fileInput.valid) return false

      return new Promise((resolve, reject) => {
        if (!files || files.length === 0 || !/^image\/\w+$/.test(file.type)) { reject(new Error('Please choose an image file.')) }

        if (!URL) { reject(new Error('Your browser is not supported.')) }

        // const file = files[0]
        resolve(file)
      })
    },
    change ({ target }) {
      this.read(target.files)
        .then(file => {
          target.value = ''
          this.$emit('loader-load-file', file)
        })
        .catch(e => {
          target.value = ''
          this.error(e)
        })
    },

    drop (e) {
      e.preventDefault()
      this.read(e.dataTransfer.files)
        .then(file => {
          this.$emit('loader-load-file', file)
        })
        .catch(this.error)
    },
    dragover (e) {
      e.preventDefault()
    },
    error (e) {
      this.$message.error(e && e.message ? e.message : e)
    }
    // update (data) {
    // Object.assign(this.data, data)
    // }

  }
}
</script>

<style lang="scss" scoped>
.loader {
  font-size: 22px;
  padding: 20px;
  .requisites{
    font-size: 18px;
    color: rgb(37, 35, 131);
  }
  label {
    text-align: center;
    border: 2px dashed #d9d9d9;
    border-radius: 6px;
    .el-icon-upload {
      font-size: 12em;
      color: #c0c4cc;
    }
    .browse {
      color: #0074d9;
      cursor: pointer;
      margin-left: 0.25rem;

      &:hover {
        color: #08f;
        text-decoration: underline;
      }
    }
  }
}
</style>
