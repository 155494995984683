import request from '@/utils/request'

export function uploadImageBase64 (data, config) {
  return request({
    url: '/uploadImageBase64',
    method: 'post',
    data,
    ...config
  })
}

export function uploadImageFile (data, config) {
  return request({
    url: '/uploadImageFile',
    method: 'post',
    data,
    ...config
  })
}
