// import _ from 'lodash'

export default {
  data () {
    return {
      innerValue: {},
      isLoadedImage: false,
      editorEl: null
    }
  },
  created () {
    // console.log('created -> this.options', this.options)
    // console.log('created -> this.innerValue', this.innerValue)

  },
  methods: {
    async onErrorImage () {
      this.isLoadedImage = false
      this.$refs[this.keyComponent].syncValue(null)
      await this.$refs[this.keyComponent].validate()
    },
    async onLoadImage () {
      this.isLoadedImage = true
      this.$refs[this.keyComponent].syncValue(this.innerValue.path)
      await this.$refs[this.keyComponent].validate()
    },
    async onSavedImage (image) {
      this.innerValue = image
      this.$modal.hide(this.keyComponent)

      // this.$refs[this.keyComponent].syncValue(this.innerValue.path)
      // const valid = await this.$refs[this.keyComponent].validate()
      // console.log('onSavedImage -> valid', valid)
    },
    openModal () {
      this.$modal.show(this.keyComponent)
    },
    clear () {
      this.innerValue = {}
    },
    opened () {
      // this.editorEl = this.$refs[this.keyComponent]
      // console.log('opened ->  this.editorEl', this.editorEl)
    }
  },
  computed: {
    rules: function () {
      let found = false
      if (this.frontRules) { found = this.frontRules.find(rule => rule.value === 'required') }

      return found ? 'required' : undefined
    },
    pathStorageThumb () {
      const path = this.$store.getters['settings/findStorage'](this.innerValue.disk)
      return (path && this.innerValue.pathThumb)
        ? path + this.innerValue.pathThumb
        : ''
    },
    pathStorage () {
      const path = this.$store.getters['settings/findStorage'](this.innerValue.disk)
      return (path && this.innerValue.path)
        ? path + this.innerValue.path
        : ''
    }

  }
}
