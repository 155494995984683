<template>
  <el-container class="tui-editor h-100" >
    <loader-editor
      v-if="!isLoadedFile"
      ref="loader"
      @loader-load-file="onLoadedFile"
      :frontRules="options.frontRules"
    />

    <el-main v-if="isLoadedFile">
      <div ref="tuieditor" class="d-flex w-100 h-100"></div>

    </el-main>

    <el-aside v-if="isLoadedFile" class="form-editor"  width="25%">
      <div class="clearfix">
        <h2 class="float-left p-0">
          <i class="el-icon-picture-outline"></i> {{ options.label }}
        </h2>
      </div>

      <ValidationObserver ref="form" v-slot="{ valid }">
        <el-form :label-position="'top'" :model="formImage">
          <ValidationProvider name="Title" rules="required" v-slot="{ errors }">
            <el-form-item :error="errors[0]" label="Title">
              <el-input v-model="formImage.title"></el-input>
            </el-form-item>
          </ValidationProvider>

          <ValidationProvider
            name="Alternate text"
            rules="required"
            v-slot="{ errors }"
          >
            <el-form-item
              :error="errors[0]"
              label="Alternate text for an image"
            >
              <el-input v-model="formImage.alt"></el-input>
            </el-form-item>
          </ValidationProvider>

          <el-row class="mb-2">

            <el-col :span="12">
              <h3>Features Output</h3>
              <ul class="list-features">
                <li v-if="options.key"><b>Key:</b> {{ options.key }}</li>

                <li v-if="options.format">
                  <b>Format:</b> {{ options.format.toUpperCase() }}
                </li>
                <li v-if="options.quality">
                  <b>Quality:</b> {{ options.quality }}%
                </li>
                <li v-if="options.disk"><b>Disk:</b> {{ options.disk }}</li>
                <li v-if="options.folder">
                  <b>Folder:</b> {{ options.folder }}
                </li>
              </ul>
            </el-col>
          </el-row>

          <el-form-item>
            <el-progress
              v-if="uploadPercentage !== 0"
              :percentage="uploadPercentage"
              :status="uploadPercentage | progressColor"
            ></el-progress>
            <el-button-group>

              <el-button
                v-if="isLoadedFile"
                type="primary"
                title="Delete"
                icon="fa fa-trash"
                @click="change('delete')"
                :loading="loading"
              >
                Delete
              </el-button>

              <el-button
                v-if="isLoadedFile"
                type="success"
                title="Upload & Save"
                icon="fa fa-upload"
                @click="upload"
                :disabled="!valid"
                :loading="loading"
              >
                Upload & Save
              </el-button>
            </el-button-group>
          </el-form-item>
        </el-form>
      </ValidationObserver>
    </el-aside>
  </el-container>
</template>

<script>

import { uploadImageBase64 } from '@/api/image'
import loaderImage from '../LoaderImage'
import ImageEditor from 'tui-image-editor'
import customTheme from './customTheme'

export default {
  components: {
    'loader-editor': loaderImage
  },
  filters: {
    statusColor (status) {
      return status ? 'color: green' : 'color: red'
    },
    statusIcon (status) {
      return status ? 'el-icon-success' : 'el-icon-error'
    },
    progressColor (percentage) {
      if (percentage === 100) {
        return 'success'
      } else if (percentage > 60) {
        return 'warning'
      } else {
        return 'exception'
      }
    }
  },
  props: {

    options: {
      type: Object,
      default: () => ({})
    }
  },
  created () {
    this.configEditor.includeUI.menu = this.options.menuOptions
  },
  data () {
    return {
      editorInstance: null,
      loading: false,
      isLoadedFile: false,
      uploadPercentage: 0,
      formImage: {
        title: '',
        alt: ''
      },
      configEditor: {
        usageStatistics: false,
        includeUI: {
          theme: customTheme
          // locale: {
          //   Download: 'Save'
          // },
          // Todo: ajustar en dialog elementos menu
          // menu: ['crop', 'flip', 'rotate'],
          // loadImage: {
          //   path: 'https://picsum.photos/1200/800',
          //   name: 'SampleImage'
          // }
          // initMenu: 'crop'
        }
      }
    }
  },

  methods: {
    async upload () {
      if (!this.isLoadedFile) { return false }
      this.loading = true
      const canvasSize = this.editorInstance.getCanvasSize()
      const dataSend = Object.assign({}, this.options, this.formImage, canvasSize)
      dataSend.image = await this.editorInstance.toDataURL()
      dataSend.resize = false

      await uploadImageBase64(dataSend, {
        onUploadProgress: (progressEvent) => {
          this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
        }

      })
        .then((response) => {
          const res = response.data
          this.$message({
            showClose: true,
            message: 'Done!',
            type: 'success'
          })
          this.$emit('saved-image', res)

          // this.$emit('update:value', res)
        })
        .catch(this.responseCatch)
        .finally(() => {
          this.uploadPercentage = 0
          this.loading = false
        })
    },
    change (action) {
      switch (action) {
        case 'delete':
          this.editorInstance.destroy()
          this.isLoadedFile = false
          break
        default:
      }
    },
    async onLoadedFile (file) {
      this.isLoadedFile = true
      await this.$refs.tuieditor

      this.formImage.fileName = file.name
      this.formImage.mimeType = file.type

      this.configEditor.includeUI.loadImage = {
        path: URL.createObjectURL(file),
        name: file.name
      }

      this.editorInstance = new ImageEditor(this.$refs.tuieditor, this.configEditor)
      URL.revokeObjectURL(file)

      // this.editorInstance.on('objectActivated', function (props) {
      //   console.log(props)
      //   console.log(props.type)
      //   console.log(props.id)
      // })

      // this.editorInstance.ui.crop.eventHandler.apply.on('apply', function (props) {
      //   console.log(props)
      // })

      // this.editorInstance.startDrawingMode('CROPPER')
      // this.editorInstance.setCropzoneRect(1.5)
      // this.editorInstance.crop({ left: 100, top: 100, width: 100, height: 200 })
    }
  },
  destroyed () {
    if (this.editorInstance) {
      // Object.keys(this.$listeners).forEach(eventName => {
      //   this.editorInstance.off(eventName)
      // })
      this.editorInstance.destroy()
    }
    this.editorInstance = null
  }

}
</script>

<style lang="scss" scoped>
.tui-editor {
  .capitalize {
    text-transform: capitalize;
  }
  .form-editor {
    border: 1px solid #eee;
    padding: 15px;
    overflow: auto;
    height: 100vh;
    ul.list-features {
      line-height: 2;
      padding-left: 1rem;
      & > li {
        list-style-type: none;
      }
    }
  }
}
</style>
