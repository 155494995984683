<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancelDialog"
    :title="dialog.title"
    :visible.sync="isVisible"
  >
    <ValidationObserver ref="dialogForm" v-slot="{ invalid }">
      <el-form :model="localData">
        <input-select
          placeholder="Please select a type"
          v-model="localData.type"
          label="Input type"
          frontRules="required"
        >
          <el-option
            v-for="item in dialog.types"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </input-select>

        <input-text
          v-model="localData.key"
          frontRules="required|alpha_dash"
          label="Input key"
          autocomplete="off"
        />

        <input-text
          v-model="localData.label"
          frontRules="required|max:250"
          label="Input label"
          autocomplete="off"
        />

        <input-text
          v-model="localData.placeholder"
          frontRules="max:500"
          label="Placeholder text"
          autocomplete="off"
        />

        <input-text
          v-model="localData.helper"
          frontRules="max:500"
          label="Helper text"
          autocomplete="off"
        />

                <template v-if="isTextArea">
  <el-divider content-position="left">TextArea Config</el-divider>
              <input-number
                v-model="localData.rows"
                frontRules="required"
                label="Rows length"
                size="medium"
                :min="1"
                :step="1"
                step-strictly
              />

        </template>
<el-divider content-position="left">Input Config</el-divider>
        <el-switch
          v-model="localData.showWordLimit"
          active-text="Show Word Limit"
          class="mb-5"
        >
        </el-switch>
        <br>

        <el-switch
          v-model="localData.showMaxlength"
          active-text="Max Limit"
          class="mb-5 mr-5"
           @change="deleteProperty('maxlength', !localData.showMaxlength)"
        >
        </el-switch>
        <template v-if="localData.showMaxlength">

              <input-number
                v-model="localData.maxlength"
                frontRules="required"
                label="Max length"
                size="medium"
                :min="1"
                :step="1"
                step-strictly
              />

        </template>

        <select-rules
          :configRules="dialog.frontRules"
          :rules.sync="data.frontRules"
        >
          <template v-slot:title>
            Add or Create FrontEnd Rules Validate
          </template>
          <template v-slot:link>
            <el-link
              target="_blank"
              href="https://logaretm.github.io/vee-validate/guide/rules.html#rules"
              icon="el-icon-document"
              >View Available Validation Rules - VeeValidate
            </el-link>
          </template>
        </select-rules>

        <select-rules
          :configRules="dialog.backRules"
          :rules.sync="data.backRules"
        >
          <template v-slot:title>
            Add or Create BackEnd Rules Validate
          </template>
          <template v-slot:link>
            <el-link
              target="_blank"
              href="https://laravel.com/docs/8.x/validation#available-validation-rules"
              icon="el-icon-document"
              >View Available Validation Rules - Laravel</el-link
            >
          </template>
        </select-rules>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog">Cancel</el-button>

        <el-button
          v-if="isEdit"
          type="primary"
          :disabled="invalid"
          @click="editDialog"
          >Edit</el-button
        >

        <el-button
          v-if="!isEdit"
          type="primary"
          :disabled="invalid"
          @click="saveDialog"
          >Save</el-button
        >
      </span>
    </ValidationObserver>
  </el-dialog>
</template>

<script>
import mixinDialog from './mixin/dialogForm'
import selectRules from '../Forms/SelectRules'
export default {
  components: {
    'select-rules': selectRules
  },
  mixins: [mixinDialog],
  computed: {
    isTextArea: function () {
      return this.data.type === 'textarea'
    }
  }
}
</script>
