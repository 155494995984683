<template>
  <el-container>
    <el-header>
      <el-divider content-position="left">
        <slot name="title"></slot>
      </el-divider>
    </el-header>
    <el-main>
      <el-card class="mb-3" shadow="none">
        <el-row class="mb-3">
          <slot name="link"></slot>
        </el-row>
        <el-row>
          <el-select
            v-model="selectedRule"
            filterable
            allow-create
            clearable
            placeholder="Choose Validate Rules"
          >
            <el-option
              v-for="item in configRules"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button
            :disabled="selectedRule == ''"
            class="mx-3"
            size="mini"
            @click="addRule(selectedRule)"
            type="primary"
            icon="el-icon-plus"
            circle
          ></el-button>
        </el-row>
        <el-row :gutter="12" v-if="elementsRules && elementsRules.length">
          <el-divider></el-divider>
          <span v-for="(element, index) in elementsRules" :key="index">
            <el-col :span="8">
              <el-card shadow="always">
                {{ element.label }}
                <el-button
                  style="float: right; margin-top: -5px"
                  @click="removeRule(index)"
                  type="danger"
                  size="mini"
                  icon="el-icon-minus"
                  circle
                ></el-button>
              </el-card>
            </el-col>
          </span>
        </el-row> </el-card
    ></el-main>
  </el-container>
</template>
<script>
import _ from 'lodash'
import {
  stringToSlug
} from '@/utils'

export default {
  props: {
    configRules: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    rules: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }

  },

  data () {
    return {
      selectedRule: '',
      elementsRules: this.rules || []
    }
  },

  methods: {
    addRule: function (value) {
      if (!value) return false
      let rule = _.find(this.configRules, {
        value: value
      })

      if (rule === undefined) {
        value = stringToSlug(value)
        rule = {
          value: value,
          label: value
        }
      }
      if (this.elementsRules === undefined) this.elementsRules = []
      this.elementsRules.push(rule)
      this.elementsRules = _.uniqBy(this.elementsRules, 'value')

      // this.$emit('change', this.elementsRules)
      this.$emit('update:rules', this.elementsRules)
    },
    removeRule: function (index) {
      // var index = this.elementsRules.indexOf(rule)
      this.elementsRules.splice(index, 1)
      // this.$emit('change', this.elementsRules)

      this.$emit('update:rules', this.elementsRules)
    }
  }
}
</script>
