<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.label || $attrs.labelValidation"
    :rules="rulesLink"
    v-slot="{ errors }"
    :ref="keyComponent"
  >
    <el-form-item :error="errors[0]" :label="$attrs.label">
      <el-input
        v-on="inputListeners"
        v-bind="$attrs"
        v-model="link"
        :ref="inputRef"
        class="input-link"
      >
        <template v-slot:prepend>
          <el-select
            :class="'target_'+keyComponent"
            v-model="target"
            placeholder="Select a target"
            @change="onChange"
          >
            <el-option
              v-for="item in storeComponent.input.target"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>

        <template v-if="showPrepend" v-slot:append>
          <span v-html="iconPrepend"></span>
        </template>
      </el-input>
    </el-form-item>
  </ValidationProvider>
</template>

<script>

import inputElemet from './mixin/inputElemet'
import { extend } from 'vee-validate'

extend('target', {
  params: ['class'],
  validate (value, args) {
    const target = document.querySelector('.' + args.class + ' input')
    return !!target.value
  },
  message: 'The {_field_} target is required'
})

export default {
  mixins: [inputElemet],
  data () {
    return {

      target: '',
      innerValue: {
      }
    }
  },
  async created () {
    this.storeComponent = this.$store.state.components.inputLink
    this.innerValue.type = this.configElement.type
  },
  async mounted () {
    if (this.innerValue.target) {
      this.target = this.innerValue.target
    }
  },
  computed: {
    link: {
      set (value) {
        this.updateInnerValue({
          href: value
        })
      },
      get () {
        return this.innerValue.href
      }

    },
    rulesLink: function () {
      const rules = this.rules.split('|')
      rules.push('target:target_' + this.keyComponent)
      if (this.configElement.type === 'url') { rules.push(this.configElement.isHttps ? 'https' : 'http') }
      if (this.configElement.type === 'email') { rules.push('email') }

      return rules.join('|')
    },
    showPrepend: function () {
      return ['url', 'email', 'phone', 'whatsapp', 'dataUrl'].includes(this.configElement.type)
    },
    iconPrepend: function () {
      let ico = ''
      switch (this.configElement.type) {
        case 'url':
          ico = '<i class="fas fa-link"></i>'
          break
        case 'email':
          ico = '<i class="fas fa-envelope"></i>'
          break
        case 'phone':
          ico = '<i class="fas fa-phone"></i>'
          break
        case 'whatsapp':
          ico = '<i class="fab fa-whatsapp"></i>'
          break
        default:
          break
      }
      return ico
    }

  },
  methods: {
    async onChange (value) {
      this.updateInnerValue({
        target: value

      })
      await this.$refs[this.keyComponent].validate()
    },
    updateInnerValue (newValues) {
      newValues.type = this.configElement.type
      this.innerValue = Object.assign({}, this.innerValue, newValues)
    }
  }
}
</script>

<style  lang="scss">
.input-link {
  .el-input-group__prepend {
    background-color: #fafafa;
    width: 25%;
    color: #606266;
  }
}
</style>
