<template>
  <el-dialog
    custom-class="dialog-image-editor"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancelDialog"
    :title="dialog.title"
    :visible.sync="isVisible"
  >
    <ValidationObserver ref="dialogForm" v-slot="{ invalid }">
      <el-form label-position="top" :model="localData">
        <input-text
          v-model="localData.key"
          frontRules="required|alpha_dash"
          label="Input key"
          autocomplete="off"
        />

        <input-text
          v-model="localData.label"
          frontRules="required|max:250"
          label="Input label"
          autocomplete="off"
        />

        <input-text
          v-model="localData.helper"
          frontRules="max:500"
          label="Helper text"
          autocomplete="off"
        />

        <el-divider content-position="left">Image Menu Options</el-divider>

        <el-checkbox-group v-model="localData.menuOptions" size="small">
          <el-checkbox-button
            v-for="option in dialog.menuOptions"
            :label="option"
            :key="option"
            >{{ option }}</el-checkbox-button
          >
        </el-checkbox-group>

        <el-divider content-position="left">Image Format</el-divider>

        <el-select
          v-model="localData.format"
          placeholder="Please select format"
          class="w-50"
        >
          <el-option
            v-for="item in dialog.formats"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-divider content-position="left">Image Disk</el-divider>

        <el-select
          v-model="localData.disk"
          placeholder="Please select Disk"
          class="w-50"
        >
          <el-option
            v-for="(item, key) in dialog.disks"
            :key="key"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>

        <el-divider content-position="left">Image Folder</el-divider>

        <input-text
          v-model="localData.folder"
          frontRules="max:255"
          placeholder="Please input Folder"
        />

        <input-number
          v-model="localData.quality"
          frontRules="required"
          label="Quality Image"
          size="medium"
          :min="10"
          :max="100"
          step-strictly
          :step="10"
        />

        <select-rules
          :configRules="dialog.frontRules"
          :rules.sync="data.frontRules"
        >
          <template v-slot:title>
            Add or Create FrontEnd Rules Validate
          </template>
          <template v-slot:link>
            <el-link
              target="_blank"
              href="https://logaretm.github.io/vee-validate/guide/rules.html#rules"
              icon="el-icon-document"
              >View Available Validation Rules - VeeValidate
            </el-link>
          </template>
        </select-rules>

        <select-rules
          :configRules="dialog.backRules"
          :rules.sync="data.backRules"
        >
          <template v-slot:title>
            Add or Create BackEnd Rules Validate
          </template>
          <template v-slot:link>
            <el-link
              target="_blank"
              href="https://laravel.com/docs/8.x/validation#available-validation-rules"
              icon="el-icon-document"
              >View Available Validation Rules - Laravel</el-link
            >
          </template>
        </select-rules>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog">Cancel</el-button>

        <el-button
          v-if="isEdit"
          type="primary"
          :disabled="invalid"
          @click="editDialog"
          >Edit</el-button
        >

        <el-button
          v-if="!isEdit"
          type="primary"
          :disabled="invalid"
          @click="saveDialog"
          >Save</el-button
        >
      </span>
    </ValidationObserver>
  </el-dialog>
</template>
<script>

import mixinDialog from './mixin/dialogForm'
import selectRules from '../Forms/SelectRules'
export default {
  components: {
    'select-rules': selectRules
  },
  mixins: [mixinDialog],
  created () {
    console.log(this.localData)
  },
  data () {
    return {
      isFreeSize: this.data.aspectRatio === 'free',
      isCustomSize: this.data.aspectRatio === 'custom'
    }
  },

  methods: {
    onChangeAspectSelect (aspectString) {
      const aspect = aspectString.split('/')
      const aspectW = aspect[0]
      this.isFreeSize = aspectW === 'free'
      this.isCustomSize = aspectW === 'custom'

      if (this.isFreeSize) return false

      if (this.isCustomSize) {
        this.calculateHeight(this.localData.aspectWidth, this.localData.aspectHeight, this.localData.width)
      } else {
        const aspectH = aspect[1]
        this.calculateHeight(aspectW, aspectH, this.localData.width)
      }
    },
    updateImageSize () {
      if (this.isFreeSize) return false

      if (this.isCustomSize) {
        this.calculateHeight(this.localData.aspectWidth, this.localData.aspectHeight, this.localData.width)
      } else {
        const aspect = this.localData.aspectRatio.split('/')
        this.calculateHeight(aspect[0], aspect[1], this.localData.width)
      }
    },
    calculateHeight (aspectW, aspectH, width) {
      this.localData.height = Math.floor((aspectH * width) / aspectW)
    }
  }
}
</script>
